export function useCompositionEvent() {
  const ctx = getCurrentInstance()
  const isComposing = ref(false)

  const events = ref<Record<string, Function>>({
    compositionstart: () => {
      isComposing.value = true
      ctx?.emit('compositionstart')
    },
    compositionupdate: (e: CompositionEvent) => {
      ctx?.emit('compositionupdate', e)
    },
    compositionend: () => {
      nextTick(() => (isComposing.value = false))
      ctx?.emit('compositionend')
    },
  })

  return {
    isComposing,
    events,
  }
}
